import Icon from 'components/Icon'
import { useEffect, useRef, useState } from 'react'

// Hooks
import useAuth from 'hooks/useAuth'
import { Button, Form } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import '../../scss/components/login-modal.scss'
import Image from 'components/Image'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'

import { setUser } from 'redux/stateSlice'

const LoginModal = () => {
  let auth = useAuth()
  let navigate = useNavigate()
  let location = useLocation()
  let dispatch = useDispatch()

  const { currentUser } = useSelector((state) => state.state)

  const emailInput = useRef()

  const [email, setEmail] = useState(currentUser ? currentUser.email : '')
  const [fullName, setFullName] = useState(currentUser ? currentUser.fullName : '')
  const [address, setAddress] = useState(currentUser ? currentUser.address : '')
  const [city, setCity] = useState(currentUser ? currentUser.city : '')
  const [country, setCountry] = useState(currentUser ? currentUser.country : '')
  const [postcode, setPostcode] = useState(currentUser ? currentUser.postcode : '')
  const [telephone, setTelephone] = useState(currentUser ? currentUser.telephone : '')
  const [isCapsOn, setIsCapsOn] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [forgottonPassword, setIsPwdTab] = useState(false)

  let from = location.state?.from?.pathname || '/'

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email)
      setFullName(currentUser.fullName)
      setAddress(currentUser.address)
      setCity(currentUser.city)
      setCountry(currentUser.country)
      setPostcode(currentUser.postcode)
      setTelephone(currentUser.telephone)
    }
  }, [currentUser])

  function handleSubmit(event) {
    event.preventDefault()

    let formData = new FormData(event.currentTarget)

    let credentials = {
      loginName: formData.get('userName'),
      password: formData.get('password'),
      rememberMe: formData.get('rememberMe'),
    }

    if (process.env.REACT_APP_SPOOF) {
      auth.spoof(() => navigate(from, { replace: true }))
    } else {
      auth.signin(credentials, (res) => dispatch(setUser(res)))
    }
  }

  function handleUpdate(event) {
    event.preventDefault()

    let formData = new FormData(event.currentTarget)

    let credentials = {
      email: formData.get('email'),
      fullName: formData.get('fullName'),
      address: formData.get('address'),
      city: formData.get('city'),
      country: formData.get('country'),
      postcode: formData.get('postcode'),
      telephone: formData.get('telephone'),
      reminders: formData.get('reminders'),
      eventInformation: formData.get('eventInformation'),
      otherInformation: formData.get('otherInformation'),
    }

    if (process.env.REACT_APP_SPOOF) {
      auth.spoof(() => navigate(from, { replace: true }))
    } else {
      auth.update(credentials, (res) => {
        toast.success('Details updated')
      })
    }
  }

  function handleLogout() {
    auth.signout(() => {
      auth.check((res) => dispatch(setUser(res)))
    })
  }

  function handleForgottenPassword(event) {
    event.preventDefault()

    let formData = new FormData(event.currentTarget)

    let credentials = {
      loginName: formData.get('userName'),
    }

    auth.forgottenPassword(credentials)
  }

  const checkCapsLock = (e) => {
    setIsCapsOn((e.getModifierState && e.getModifierState('CapsLock')) || false)
  }

  // Redirect back to `from` or `/account` by default
  // useEffect(() => {
  //   if (auth.user) {
  //     navigate(location.state?.from?.pathname || '/account', { replace: true })
  //   }
  // })

  // Focus the first input on load
  // Check if caps is on
  useEffect(() => {
    window.addEventListener('keyup', checkCapsLock)
    window.addEventListener('keydown', checkCapsLock)
    if (emailInput.current) {
      emailInput.current.focus()
    }
    // TODO: review and make into hook if multiple components use this
    document.documentElement.classList.add('html-login-view')
    return () => {
      window.removeEventListener('keyup', checkCapsLock)
      window.removeEventListener('keydown', checkCapsLock)
      document.documentElement.classList.remove('html-login-view')
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      toggleOpen()
    }
  }, [location])

  useEffect(() => {}, [currentUser])

  const toggleOpen = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true)
    isOpen ? document.body.classList.remove('modal-open') : document.body.classList.add('modal-open')
  }

  const togglePwdTab = () => {
    forgottonPassword ? setIsPwdTab(false) : setIsPwdTab(true)
  }

  return (
    <>
      <button onClick={toggleOpen} className={'login-toggle'}>
        <p>{!currentUser ? 'Members Login' : 'Hi, ' + currentUser.username}</p>
        <Icon name="baliMyna" />
      </button>

      <div className={'modal login-modal' + (isOpen ? ' open' : '') + (currentUser ? ' logged-in' : '')}>
        <Button variant="transparent" onClick={toggleOpen} className="">
          <Icon name="close" />
          <span>Close</span>
        </Button>
        {!currentUser ? (
          <div>
            {/* NOTE: Phase 2 */}
            {/* <div className="d-flex gap-3 w-100 flex-wrap justify-content-center my-3 align-items-center">
                  <IconButton variant='light'><GooglePlus/>GOOGLE</IconButton>
                  <IconButton variant='light' className='facebook'><FaFacebookF/>FACEBOOK</IconButton>
                </div>
                <p className="divider mb-0">or sign in with email</p> */}
            {forgottonPassword ? (
              <Form onSubmit={handleForgottenPassword}>
                <h2>Forgotten Password</h2>

                <Form.Control name="userName" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" ref={emailInput} autoComplete="username" />

                <Button className="button green1" type="submit">
                  Send Password Reset
                </Button>

                <Button className="button green1 inverted" type="back" onClick={togglePwdTab}>
                  Back
                </Button>
              </Form>
            ) : (
              <Form onSubmit={handleSubmit} className="login">
                <h2>Members Login</h2>

                <Form.Group>
                  <Form.Control
                    name="userName"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    ref={emailInput}
                    autoComplete="username"
                  />
                </Form.Group>

                <span className={`text-info mb-3 ${isCapsOn ? '' : ' hidden'}`}>WARNING: Caps Lock is on!</span>

                <Form.Group>
                  <Form.Control name="password" type="password" placeholder="Password" autoComplete={'current-password'} />
                </Form.Group>

                <Form.Check type="checkbox" className="remember-me">
                  <Form.Check.Input name="rememberMe" id="remember-me" type="checkbox" />
                  <Form.Check.Label className="" htmlFor="remember-me">
                    Remember me
                  </Form.Check.Label>
                </Form.Check>
                <p className="forgot-password" to="forgot-password" onClick={togglePwdTab}>
                  <u>Forgot Password?</u>
                </p>

                <Button className="button green1" type="submit">
                  LOGIN
                </Button>
                <p>
                  Dont have an account?{' '}
                  <Link to={'join'} className="underline strong">
                    Become a member
                  </Link>
                </p>
              </Form>
            )}
          </div>
        ) : (
          <div className="container">
            <Form onSubmit={handleUpdate}>
              <h2>Account Details</h2>

              <Form.Group>
                <Form.Check.Label className="" htmlFor="email">
                  Email
                </Form.Check.Label>
                <Form.Control name="email" type="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Check.Label className="" htmlFor="fullName">
                  Full Name
                </Form.Check.Label>
                <Form.Control name="fullName" type="text" placeholder="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Check.Label className="" htmlFor="address">
                  Address
                </Form.Check.Label>
                <Form.Control name="address" type="text" placeholder="address" value={address} onChange={(e) => setAddress(e.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Check.Label className="" htmlFor="city">
                  City
                </Form.Check.Label>
                <Form.Control name="city" type="text" placeholder="city" value={city} onChange={(e) => setCity(e.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Check.Label className="" htmlFor="country">
                  Country
                </Form.Check.Label>
                <Form.Control name="country" type="text" placeholder="country" value={country} onChange={(e) => setCountry(e.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Check.Label className="" htmlFor="postcode">
                  Postcode
                </Form.Check.Label>
                <Form.Control name="postcode" type="text" placeholder="postcode" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Check.Label className="" htmlFor="telephone">
                  Telephone
                </Form.Check.Label>
                <Form.Control name="telephone" type="text" placeholder="telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)} />
              </Form.Group>

              <Button className="button green1" type="submit">
                Save
              </Button>
            </Form>
          </div>
        )}
        {!currentUser ? (
          <div>
            <Image name={'kingfishers'} blowout={{ imageSize: 500, blowoutSize: 50, id: 'kingfishers' }} />
          </div>
        ) : (
          <div className="details">
            <div className="subscription-details">
              {currentUser.subscriptions ? (
                <>
                  <h2>Subscription Details</h2>
                  <span>Date subscribed: {currentUser.subscriptions.dateSubscribed}</span>
                  <span>Date subscription renews: {currentUser.subscriptions.datePeriodEnd}</span>
                  <Button
                    onClick={() => {
                      window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL, '_blank').focus()
                    }}
                    className="button green1"
                  >
                    Manage Subscription
                  </Button>
                </>
              ) : (
                <>
                  <h2>Subscription Details</h2>
                  <Link className={'button green1 subscribe'} to={'/join'}>
                    Subscribe
                  </Link>
                </>
              )}
            </div>
            <div>
              <Button
                onClick={() => {
                  let credentials = {
                    loginName: currentUser.email,
                  }

                  auth.forgottenPassword(credentials)

                  toast.success('Password reset sent.')
                }}
                className="button green1"
              >
                Reset Password
              </Button>
              <Button onClick={handleLogout} className="button green1">
                Logout
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default LoginModal
