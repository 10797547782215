import React from 'react'
import CustomNavLink from '../CustomNavLink'
import { useSelector } from 'react-redux'
import Icon from '../Icon'
import SocialIcons from '../SocialIcons'
import { Link } from 'react-router-dom'

const Footer = () => {
  const { navigationNodes: nodes, globals } = useSelector((state) => state.api)
  const date = new Date().getFullYear()

  return (
    <footer id="site-footer">
      <div>
        <div>
          <a href="/" className="logo">
            <Icon className="logo" name="logo" />
          </a>
          <SocialIcons />
        </div>
        <div>
          {nodes['footer'].map((node, index) => {
            return node ? (
              <React.Fragment key={index}>
                <CustomNavLink node={node} />
              </React.Fragment>
            ) : (
              ''
            )
          })}
        </div>
        <div className={'member-column'}>
          <Link key={'becomeAMember'} className={'button green1'} to={'/subscribe'}>
            Become a member
          </Link>
        </div>
      </div>
      <div>
        {globals.siteGlobals.seo.address ? <p dangerouslySetInnerHTML={{ __html: globals.siteGlobals.seo.address }}></p> : <></>}
        <p>
          Copyright &copy; {date} The Avicultural Society | <Link to={'/privacy'}>Policies</Link>
        </p>
      </div>
    </footer>
  )
}

export default Footer
